*,
*::after,
*::before {
  box-sizing: border-box;
}

$tablet-screen: 769px;
$mobile-screen: 480px;

html, body {
  overflow-x: hidden;
  font-size: 16px;

  @media screen and (max-width: $tablet-screen) {
    font-size: 12px;
  }

  @media screen and (max-width: 374px){
    font-size: 10px;
  }
}

body {
  font-family:  'Gotham Pro', sans-serif;
  font-weight: normal;
  animation: fadein 1s;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

strong {
  font-weight: bold;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.container {
  display: flex;
  max-width: 1400px;
  padding: 130px 15px 0;
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
  justify-content: space-around;

  @media screen and (max-width: $tablet-screen) {
    flex-wrap: wrap;
    justify-content: center;
  }

  h1 {
    margin-top: 120px;
    color: #E30E18;
    font-size: 3.55rem;
    font-weight: normal;
    text-transform: uppercase;

    @media screen and (max-width: $tablet-screen){
      margin-top: 50px;
      background-color: #fff;
    }
  }
}

.logo {
  display: block;
  margin-top: 46px;
}

.button {
  display: inline-block;
  margin-top: 60px;
  border-radius: 10px;
  transition: all 0.25s;
  max-width: 45%;

  &:last-child {
    margin-left: 43px;

    @media screen and (max-width: $tablet-screen){
      margin-left: 10px;
    }
  }

  &:hover {
    box-shadow: 5px 5px 40px 3px rgba(154, 154, 154, 0.3);
  }
}

.phone {
  margin-left: 100px;
  margin-top: 0;
  object-fit: contain;

  @media screen and (max-width: $tablet-screen){
    margin-left: 0;
  }

  @media screen and (max-width: $mobile-screen) {
    margin-top: -70px;
  }
}

.button-wrapper {
  display: block;
}
